.zf-main-middle{
    position: relative;
    width: 100%;
    height: 100%;
}
.zf-cartoon{
    position: relative;
    width: 100%;
    height: auto;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.zf-swipe{
    position: relative;
    width: 1000px;
    height: 200px;
    background-color: #1b65b9;
    overflow: hidden;
}
.zf-animation{
    width: 2000px;
    height: 200px;
    margin: 0 auto;
    font-size: 0;
    animation: znm 10s infinite linear;
}
@keyframes znm {
    10%,40% {
        transform: translate3d(0px, 0px, 0px);
    }
    50%{
        transform: translate3d(-1000px, 0px, 0px);
    }
    60%,90% {
        transform: translate3d(-1000px, 0px, 0px);
    }
    100%{
        transform: translate3d(0px, 0px, 0px);
    }
}
.zf-img{
    /*TODO 最终需要设置为1000px宽度*/
    width: 1000px;
    height: 200px;
    /*position: absolute;*/
    /*z-index: 1;*/
    /*transform: translate3d(0px, 0px, 0px);*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    float: left;
}
.zf-db-pc{
    width: 354px;
    height: 200px;
    background: url("../../../images/zfdb@pc.jpg");
    background-size: cover;
}
.zf-db-app{
    width: 122px;
    height: 200px;
    background: url("../../../images/zfdb@app.png");
    background-size: cover;
}

.zf-feature{
    position: relative;
    margin: 0;
    padding: 0;
}
.zf-feature-row{
    background: #eaf3f8;
}
.bg-white{
    background: #ffffff;
}
.bg-grey{
    background: #fafafa;
}
.zf-row-box{
    display: table;
    table-layout: fixed;
    width: 1000px;
    overflow: hidden;
    height: 500px;
    margin: 0 auto;
}
.zf-row-box-col-1{
    position: relative;
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}
.zf-row-box-col-2{
    position: relative;
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}
.zf-col-text{
    margin: 0 50px;
}
.zf-col-text-title{
    margin-bottom: 25px;
    color: dodgerblue;
    font-size: 24px;
    font-weight: normal;
}
.zf-col-text-content{
    line-height: 24px;
    font-size: 14px;
    padding-left: 15px;
    border-left: 2px solid dodgerblue;
    color: #666;
}
.zf-col-img{
    /*width: 500px;*/
    /*height: 350px;*/
    width: 400px;
    height: 250px;
}
.zf-col-img-app{
    width: 152px;
    height: 250px;
}
.zf-fl-rt{
    float: right;
}