.zf-main-header{
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 1002;
    background-color: #eaf3f8;
    box-shadow: none;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
}
.zf-main-header-wrapper{
    position: absolute;
    /*background-color: #59b4f9;*/
    /*background: #34acf3;*/
    background-color: #1b65b9;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}
.zf-main-header-content{
    position: absolute;
    left: 10%;
    width: 80%;
    min-width: 600px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
}
.item{
    flex: 1;
}
.header-left{
    min-width: 200px;
    text-align: left;
}
.zf-profile{
    /*display: inline-flex;*/
}
.zf-logo{
    width: 168px;
    height: 56px;
    background: url("../../../icons/logo-31.png") no-repeat;
    background-size: contain;
}
.zf-name{
    height: 46px;
    width: 80px;
    font-size: 16px;
    color: white;
    margin-left: 2px;
    text-align: center;
}
.zf-name-cn{
    font-weight: 600;
    font-family: cursive;
    margin-top: 3px;
}
.zf-name-en{

}
.header-middle{
    min-width: 200px;
    text-align: center;
    line-height: 50px;
}
.header-middle-content{
    display: inline-flex;
    line-height: 50px;
}
.header-middle-item{
    min-width: 80px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
.header-middle-item-selected{
    border-bottom: 2px solid #ffffff;
}
.header-right{
    min-width: 200px;
    text-align: center;
    line-height: 50px;
}