.zf-about{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    min-height: 580px;
}
.zf-about-content{
    width: 80%;
}
.zf-about-intro{

}
.zf-about-contact{
    margin-top: 28px;
}