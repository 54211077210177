.zf-main{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    background: none;
    border: none;
    overflow-x: hidden;
    overflow-y: auto;
}
.zf-main-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -1;
    background-color: #eaf3f8;
    overflow: hidden;
}
.zf-main-inferior{
    position: absolute;
    left: 0;
    width: 100%;
    top: 60px;
    /*bottom: 0;*/
}

