.zf-main-footer{
    position: relative;
    width: 100%;
    background-color: #eaf3f8;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
}
.zf-main-footer-content{
    line-height: 20px;
    padding: 28px 0;
}
.zf-footer-p{
    margin: 0;
    padding: 0;
    color: #999;
    text-decoration: none;
}
.zf-footer-p a{
    color: #999;
    text-decoration: none;
}
.zf-footer-p a:hover{
    color: #666;
}