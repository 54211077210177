.zf-product{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    min-height: 580px;
}
.zf-product-content{
    width: 10%;
}
.zf-product-intro{
    margin: 28px 0;
    width: 150px;
}